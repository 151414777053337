import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: { minWidth: "500px" },
  header: {},
  btnGroup: {
    display: "inline-flex",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: theme.spacing(1),
      maxHeight: "2.5em",
    },
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
  groupRow: {},
  timeRangeToolTip: {
    backgroundColor: `${theme.palette.grey?.main} !important`,
    maxWidth: "400px",
  },
  toolTipHeader: {},
}));
