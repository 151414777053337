import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import AccessGroupService from "../../../services/AccessGroupService";
import apiClient from "../../../auth/apiClient";
import {
  Grid,
  List,
  ListItem,
  Button,
  Box,
  Dialog,
  DialogContent
} from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { isUndefined } from "lodash";
import clsx from "clsx";
import { useStyles } from "./styles";
import ColoredLine from "../../ColoredLine";
import moment from "moment-timezone";
import Tooltip from "react-tooltip";
import { generateUUID } from "../../Rate";
import { abbreviateDays } from "../../Panels/AccessGroups/utilities";
import AccessGroupForm from "../../Forms/AccessGroup";
import useHasPermissions from "../../../hooks/useHasPermissions";
import _ from "lodash";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";

const accessGroupService = new AccessGroupService(apiClient);

/**
 * @param  {string} accessHolderID The AccessHolderID
 * @param  {string} searchTerm The group search criteria
 * @param  {string} className CSS class
 * @param  {boolean} selectable will render the select button if true
 * @param  {(a:accessGroup)=>void} onSelect Handles select
 * @param  {boolean} newData a change in this value triggers re-fetch of groups
 * @param  {string} mode Either: "manage", "assign", or "disabled"
 */
const AccessGroupList = ({
  accessHolderID,
  searchTerm,
  className,
  selectable,
  onSelect,
  newData,
  mode,
  groupTypeFilter
}) => {
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const classes = useStyles();
  const [accessGroups, setAccessGroups] = useState();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [formState, setFormState] = useState({ open: false });
  const { hasPermissions } = useHasPermissions();
  const accessGroupEdit = hasPermissions(["accessgroups.edit"]);
  const AccessGroupAssignPermission = hasPermissions(
    ["accessholders.add", "accessholders.edit"],
    true
  );
  const isSharedAccountsEnabled = useFeatureFlag("Shared Accounts");
	const isCVPSEnabled = useFeatureFlag("CVPS Dual Pairing");
  const isFlexibleParkingAccountEnabed = useFeatureFlag("Flexible Parking Account");

  useEffect(() => {
    if (!isUndefined(accessHolderID) && mode === "assign")
      fetchAssignedAccessGroups(accessHolderID, searchTerm).then();
    else if (mode === "manage") {
      fetchAccessGroups(searchTerm).then();
    }
  }, [accessHolderID, searchTerm, scopeAwareFacilityID, newData, groupTypeFilter]);

  const fetchAccessGroups = useCallback(
    // ( Presenting the user with groups to associate with its access holder )
    async searchTerm => {
      let allGroupsResponse, myGroupsResponse;
      try {
        allGroupsResponse = await accessGroupService.GetAccessGroups(
          scopeAwareFacilityID,
          100,
          0,
          searchTerm,
          groupTypeFilter
        );
        if (accessHolderID != undefined) {
          myGroupsResponse = await accessGroupService.GetAssignedAccessGroups(
            accessHolderID,
            100,
            0
          );
          let assignedGroups = myGroupsResponse?.data.collection ?? [];
          let unassignedGroups = allGroupsResponse?.data.collection.filter(
            a =>
              !assignedGroups.some(
                item => item.name === a.name && item.facilityID === a.facilityID
              )
          );
          setAccessGroupWithFeatureFlag(unassignedGroups);
        } else {
          setAccessGroupWithFeatureFlag(allGroupsResponse?.data.collection);
        }
      } catch (ex) {
        enqueueSnackbar("Failed to retrieve access groups", {
          variant: "error",
          tag: "fetchGroupsError"
        });
      }
      return;
    },
    [accessGroupService.GetAccessGroups, scopeAwareFacilityID, searchTerm, groupTypeFilter]
  );

  const fetchAssignedAccessGroups = useCallback(
    async (accessHolderID, searchTerm) => {
      let response;
      try {
        response = await accessGroupService.GetAssignedAccessGroups(
          accessHolderID,
          100,
          0,
          searchTerm
        );
      } catch {
        enqueueSnackbar("Failed to retrieve assigned access groups", {
          variant: "error",
          tag: "fetchAssignedGroupsError"
        });
      }

      setAccessGroupWithFeatureFlag(response?.data.collection);
    },
    [accessGroupService.GetAssignedAccessGroups, accessHolderID, searchTerm]
  );

  const handleUnassign = async groupID => {
    try {
      await accessGroupService.UnassignAccessHolderFromGroup(
        groupID,
        accessHolderID
      );
    } catch {
      enqueueSnackbar("Failed to unassign access group", {
        variant: "error",
        tag: "unassignGroupError"
      });
      return;
    }

    enqueueSnackbar("Successfully unassigned", { variant: "success" });

    let tmpGroups = accessGroups.slice() ?? [];
    const foundIndex = accessGroups.findIndex(x => x.accessGroupID === groupID);
    tmpGroups.splice(foundIndex, 1);
    setAccessGroupWithFeatureFlag(tmpGroups);
  };

  const handleEditClick = accessGroup => {
    setFormState({ open: true, accessGroup });
  };

  const handleEditSubmit = () => {
    setFormState({ open: false });
    if (!isUndefined(accessHolderID))
      fetchAssignedAccessGroups(accessHolderID, searchTerm);
    else fetchAccessGroups(searchTerm);
  };

  const handleAccessGroupDelete = groupID => {
    let tmpGroups = accessGroups?.slice() ?? [];
    const foundIndex = tmpGroups.findIndex(x => x.accessGroupID === groupID);
    if (foundIndex < 0) return;

    tmpGroups.splice(foundIndex, 1);
    setAccessGroupWithFeatureFlag(tmpGroups);
    setFormState({ open: false });
  };

  const setAccessGroupWithFeatureFlag = accessgroups => {
    setAccessGroups(accessgroups?.filter(x => checkFeatureFlag(x.type)));
  }

  const checkFeatureFlag = type => {
    switch (type){
      case "Shared Accounts":
        return isSharedAccountsEnabled;
			case "Valet Pairing":
				return isCVPSEnabled;
      case "Flexible Parking Account":
        return isFlexibleParkingAccountEnabed;
      default:
        return true;
    }
  }

  const getFormattedTimeRange = rules => {
    if (
      !rules ||
      rules.length === 0 ||
      !rules[0].admittanceTimes ||
      rules[0].admittanceTimes == null ||
      rules[0].admittanceTimes.length === 0
    ) {
      return <p>{"24/7"}</p>;
    } else {
      const identifier = generateUUID();
      let tmpRules = rules?.slice() ?? [];

      tmpRules = tmpRules.map(rule => {
        const tmpTimes = rule.admittanceTimes?.slice() ?? [];
        if (_.isEmpty(tmpTimes)) return {};
        const startTime = tmpTimes[0].startTime;
        const endTime = tmpTimes[0].endTime;
        const days = abbreviateDays(
          tmpTimes
            .flat()
            .sort(x => x.applicableDay)
            .map(x => x.applicableDay)
        );
        return { days, times: { startTime, endTime } };
      });

      return (
        <>
          <p className={clsx("details")} data-tip data-for={identifier}>
            Details
          </p>
          <Tooltip
            className={clsx("time-range-tool-tip", classes.timeRangeToolTip)}
            id={identifier}
            aria-haspopup="true"
          >
            <Grid
              container
              className={clsx("grid-container")}
              direction="row"
              role="time-range-tooltip"
            >
              <Grid item className={clsx("grid-item-times")} xs={6}>
                <Box
                  className={clsx("start-end-times", classes.toolTipHeader)}
                  fontWeight="bold"
                  fontSize="large"
                >
                  Start/End Times
                </Box>
              </Grid>
              <Grid item className={clsx("grid-item-days")} xs={6}>
                <Box
                  className={clsx("days-of-the-week", classes.toolTipHeader)}
                  fontWeight="bold"
                  fontSize="large"
                >
                  Days of the Week
                </Box>
              </Grid>
              {tmpRules
                ?.filter(x => !_.isEmpty(x))
                .map(({ days, times }, index) => (
                  <React.Fragment key={index}>
                    <Grid item className={clsx("grid-item-local-time", `local-time-${index}`)} xs={6}>
                      <p>{`${moment
                        .utc(times.startTime)
                        .local()
                        .format("hh:mm A")} - ${moment
                        .utc(times.endTime)
                        .local()
                        .format("hh:mm A")}`}</p>
                    </Grid>
                    <Grid item className={clsx("grid-item-join-days", `join-days-${index}`)} xs={6}>
                      <p>{days.join(", ")}</p>
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          </Tooltip>
        </>
      );
    }
  };

  return (
    accessGroups?.length > 0 && (
      <>
        <List
          role="group-list"
          className={clsx("access-group-list", classes.root, className)}
        >
          <ListItem className={clsx("access-group-list-header", classes.header)}>
            <Grid
              container
              className={clsx("grid-container-groups")}
              direction="row"
            >
              <Grid item className={clsx("grid-item-groups")} xs={3}>
                <Box
                  className={clsx("group-name-header")}
                  fontWeight="bold"
                  fontSize="large"
                >
                  Group Name
                </Box>
              </Grid>
              <Grid item className={clsx("grid-item-types")} xs={3}>
                <Box
                  className={clsx("group-type-header")}
                  fontWeight="bold"
                  fontSize="large"
                >
                  Type
                </Box>
              </Grid>
              <Grid item className={clsx("grid-item-admit-times")} xs={3}>
                <Box
                  className={clsx("group-admittance-times-header")}
                  fontWeight="bold"
                  fontSize="large"
                >
                  Admittance Times
                </Box>
              </Grid>
              <Grid item className={clsx("grid-item-blank")} xs={3} />
            </Grid>
          </ListItem>
          {accessGroups?.map((group, index) => (
            <>
                <ListItem
                  key={index}
                  className={clsx("access-group-list-item", `access-group-${group.name}`, classes.groupRow)}
                >
                  <Grid
                    container
                    className={clsx(`grid-container-${group.name}`)}
                    direction="row"
                  >
                    <Grid item className={clsx("access-group-name", `grid-item-${group.name}`)} xs={3}>
                      <p className={clsx(`${group.name}`)}>{group.name}</p>
                    </Grid>
                    <Grid item className={clsx("access-group-type", `grid-item-${group.type}`)} xs={3}>
                      <p className={clsx(`${group.type}`)}>{group.type}</p>
                    </Grid>
                    <Grid
                      item
                      className={clsx("access-group-admit-times", `grid-item-${group.admittanceRules}`)}
                      xs={3}
                    >
                      {getFormattedTimeRange(group.admittanceRules)}
                    </Grid>
                    <Grid
                      className={clsx("button-group", classes.btnGroup)}
                      item
                      xs={3}
                    >
                      {selectable === true && AccessGroupAssignPermission && (
                        <Button
                          className={clsx(`select-${group.name}`)}
                          data-testid={`select-${group.accessGroupID}`}
                          color="primary"
                          variant="contained"
                          onClick={() => onSelect(group)}
                        >
                          Select
                        </Button>
                      )}
                      {mode === "assign" && AccessGroupAssignPermission && (
                        <Button
                          className={clsx(`unassign-${group.name}`)}
                          data-testid={`unassign-${group.accessGroupID}`}
                          color="secondary"
                          variant="contained"
                          onClick={() => handleUnassign(group.accessGroupID)}
                        >
                          Unassign
                        </Button>
                      )}
                      {accessGroupEdit && (
                        <Button
                          className={clsx(`edit-${group.name}`)}
                          data-testid={`edit-${group.accessGroupID}`}
                          color="primary"
                          variant="contained"
                          onClick={() => handleEditClick(group)}
                        >
                          Edit
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </ListItem>
              <ColoredLine />
            </>
          ))}
        </List>
        <Dialog
          className={clsx("accessgroup-dialog")}
          role="accessgroup-dialog"
          fullWidth
          maxWidth="md"
          open={formState.open}
        >
          <DialogContent className={clsx("accessgroup-dialog-content")}>
            <AccessGroupForm
              className={clsx("accessgroup-form")}
              data={formState.accessGroup}
              onCancel={() => setFormState({ open: false })}
              onSubmit={handleEditSubmit}
              onDelete={handleAccessGroupDelete}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

AccessGroupList.defaultProps = {
  selectable: false,
  onSelect: () => {},
  onEditClick: () => {},
  mode: "manage",
  groupTypeFilter: ""
};

AccessGroupList.propTypes = {
  accessHolderID: PropTypes.string,
  searchTerm: PropTypes.string,
  className: PropTypes.string,
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
  mode: PropTypes.oneOf(["assign", "manage", "disabled"]),
  groupTypeFilter: PropTypes.string
};

export default AccessGroupList;
