import React from "react";
import clsx from "clsx";
import { useStyles } from "./AccessHolder.style";
import SquareChip from "../../SquareChip"
import { AUDIT_TYPES, AUDIT_SUBTYPES } from "../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";

const AccessHolderAudit = ({ onDrawer, username, action, date, payload, ...props }) => {
  const classes = useStyles();
  const _payload = JSON.parse(props?.history?.payload) ?? null;

  const getActionLabel = () => {
    switch (action) {
      case "add":
        return "Created";
      case "edit":
        return "Edited";
      case "delete":
        return "Deleted";
      default:
        return action;
    }
  };
  console.log("ACCESS HOLDER", _payload);
  const getAccessHolderName = () => {
    switch (action) {
      case "add":
      case "edit":
        return `${_payload?.accessHolder?.ContactInfo?.FirstName} ${_payload?.accessHolder?.ContactInfo?.LastName}`;
      case "delete":
        return _payload?.accessHolderID;
      default:
        return null;
    }
  };

  const handleDetailsClick = () => {
    onDrawer("access holder " + date.toString());
  }

  return (
    <>

      <tr>
        <td data-column="Area" className={clsx([classes.tdc])}><SquareChip size="small" color="primary" text={AUDIT_TYPES[props?.history?.category]}/></td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
        </td>
        <td data-column="Action" className={clsx([classes.tdc])}>
          <div>{getActionLabel()}</div>
          <div className={clsx([action, classes.groupName])}>{getAccessHolderName()}</div>
        </td>
        <td data-column="Details" className={clsx([classes.tdc,classes.details])}>
          {1==2 && (
          <FontAwesomeIcon onClick={handleDetailsClick} size="lg" className={classes.detailIcon} alt={JSON.stringify(_payload)} title={JSON.stringify(_payload)} icon={faInfoCircle}></FontAwesomeIcon>
          )}
        </td>
      </tr>

    </>
  );
};

export default AccessHolderAudit;
